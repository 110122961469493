// import React, { useState, useEffect } from "react"
import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

const Logo = ({displayText, height, padding}) => {
  // let padding = 0;
  // let containerHeight = 0;
  // if (window.innerWidth > 1000) {
  //   baseHeight=147;
  // } else {
  //   baseHeight=100;
  // }
  // // console.log(height);
  // if (height > (baseHeight - 2* 16)){
  //   padding = ((baseHeight - 2* 16)-height)/2;
  //   containerHeight = (baseHeight - 2* 16);
  // } else {
  //   padding = 0;
  //   containerHeight = height -2 * 16;
  // }
  // console.log(`height:${containerHeight} padding:${padding}`)
  return (
    <div className="container logo">
      <StaticQuery
          query ={graphql`
          query {
            strapiHeader {
              strapiId
              Title
              Subtitle
              logo {
                publicURL
              }
            }
          }
          `}
          render = {data => (
            <Link to='/'>
              <div className="columns is-centered is-mobile">
                <div className="column is-narrow image">
                  <div className="container image">
                    <img alt="" src={data.strapiHeader.logo.publicURL} />
                  </div>
                </div>
              <div className="column text" >
                  <div className="title">
                    <h1>{data.strapiHeader.Title}</h1>
                  </div>
                  <div className="subtitle">
                    <h2>{data.strapiHeader.Subtitle}</h2>
                  </div>
                </div>
              </div>
            </Link>
          )}
        />
    </div>
  )
}

export default Logo
