import React, { useState, useEffect } from "react"
import TopMenu from "./topmenu"
import Logo from "./logo"
import { MdHome } from 'react-icons/md';
const Header = ({ title, subtitle, image }) => {
  const [classMinimized, setClassMinimized] = useState(0);
  // const [paddingLogo, setPaddingLogo] = useState(0);
  // const [height, setHeight] = useState(0);
  // const [displayText, setDisplayText] = useState(0);
  // const [scrollY, setScrollY] = useLocalStorage('scrollY',0);
  // const [windowDimensions, setWindowDimensions] = useState(false);
  // change state on scroll
  // TODO persist margin in lcoalsore
  // https://dev.to/akhilaariyachandra/persistent-state-in-react-f50
  // const calculateHeaderSize = () => {
  // let headerHeightFull;
  // let headerHeightMinimized;
  // let headerMargin;
  // if (window.innerWidth > 1000) {
  //   headerHeightFull=147;
  //   headerHeightMinimized = 60;
  //   headerMargin=50;
  // } else {
  //   headerHeightFull=100;
  //   headerHeightMinimized = 50;
  //   headerMargin=10;
  // }
  // const shrinkAbleSize = (headerHeightFull - headerHeightMinimized) + 2 * headerMargin;
  // if (window.scrollY < shrinkAbleSize) {
  //   // we still can shrink something
  //   const toShrink = window.scrollY;
  //   // console.log(`can shrink ${toShrink} scrollY:${window.scrollY}`)
  //   if (toShrink < 2 * headerMargin ) {
  //     // we still can reduce margin
  //     setMargin(headerMargin - window.scrollY/2); // reduce half because margin is top and bottom
  //     setHeight(headerHeightFull);
  //     setDisplayText(true);
  //   } else {
  //     const toShrinkHeader = toShrink - 2 * headerMargin;
  //     //we can't shrink margin, but height
  //     setMargin(0);
  //     setDisplayText(false);

  //     if(toShrinkHeader < (headerHeightFull - headerHeightMinimized) ){
  //       setHeight(headerHeightFull - toShrinkHeader);
  //     } else {
  //       setHeight(headerHeightMinimized);
  //     }
  //   }
  // } else {
  //   // console.log("can't shrink");
  //   setHeight(headerHeightMinimized);
  //   setMargin(0);
  //   setDisplayText(false);
  // }
  // if (window.scrollY > 0) {
  //   setHeight(headerHeightMinimized);
  //   setMargin(0);
  //   setDisplayText(false);
  //   setPaddingLogo(0.1);
  //   setFontSizeTitle(2.8);
  // } else {
  //   setHeight(headerHeightFull);
  //   setMargin(headerMargin);
  //   setDisplayText(true);
  //   setPaddingLogo(1);
  //   setFontSizeTitle(1.1);
  // }
  // if( ( (containerHeight-headerMargin) -window.scrollY ) >= headerHeightMinimized ){

  //   if(margin - window.scrollY >= 0) {
  //     setMargin(headerMargin - window.scrollY);
  //   }
  //   console.log(margin)
  //   setHeight(containerHeight -window.scrollY);
  //   setDisplayText(true)
  //   if (( (containerHeight-headerMargin) -window.scrollY ) < headerHeightFull) {
  //     setDisplayText(false)
  //   }


  // } else {
  //   setHeight(headerHeightMinimized);
  //   setMargin(0);
  //   setDisplayText(false)
  // }
  // }
  const addClassIfScrolled = () => {
    if (window.scrollY > 50) {
      setClassMinimized('minimized');
    } else {
      setClassMinimized('');
    }
  }
  const mount = () => {
    addClassIfScrolled();
  }
  const scroll = () => {
    document.addEventListener('scroll', addClassIfScrolled, { passive: false });
    return () => {
      document.removeEventListener('scroll', addClassIfScrolled);
    };
  }
  const resize = () => {
    document.addEventListener('resize', addClassIfScrolled, { passive: false });
    return () => {
      document.removeEventListener('resize', addClassIfScrolled);
    };
  }
  useEffect(mount, []);
  useEffect(scroll, []);
  useEffect(resize, []);

  return (
    <div className={`container is-widescreen header ${classMinimized}`} >
      <header className="header " >
        <div className="columns is-mobile" >
          <div className="column is-narrow logo" >
            <Logo />
          </div>
          <div className="column menu" >
            <TopMenu />
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
