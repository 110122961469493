/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// import favicon16x16 from '../images/favicon-16x16.png'
// import favicon32x32 from '../images/favicon-32x32.png'
// import appleTouchIcon from '../images/apple-touch-icon.png'
// import Helmet from 'react-helmet'
import React from "react"
import Header from './header'
import SEO from "../components/seo"
import Start from "../templates/pages/start"
import Contact from "../templates/pages/contact"
import About from "../templates/pages/about"
import Offerings from '../templates/pages/offerings'
import Footer from '../components/footer'
import Basic from '../templates/pages/basic'

const Layout = ({ data }) => {
  return (
    <div>

    <SEO />
    
      <div>
        <Header />
        {
          data.strapiPage.path === '/' &&
            <div className="container is-widescreen content-container start">
              <main>
                <Start 
                  imageURL={data.strapiPage.mainImage.publicURL}
                  content={data.strapiPage.content}
                  secondContent={data.strapiPage.secondColumnContent}
                  headline={data.strapiPage.Headline}
                />
              </main>
            </div>
        }
        {
          data.strapiPage.path === '/aboutme' &&
            <div className="container is-widescreen content-container about">
              <main>
                <About
                  imageURL={data.strapiPage.mainImage.publicURL}
                  content={data.strapiPage.content}
                  headline={data.strapiPage.Headline}
                  secondContent={data.strapiPage.secondColumnContent}
                />
              </main>
            </div>

        }
        {
          data.strapiPage.path === '/contact' &&
          <main>
            <Contact
              content={data.strapiPage.content}
            />
          </main>
        }
        {
          data.strapiPage.path === '/offerings' &&
            <div className="container is-widescreen content-container offerings">
              <main>
                <Offerings
                  imageURL={data.strapiPage.mainImage.publicURL}
                  content={data.strapiPage.content}
                  secondContent={data.strapiPage.secondColumnContent}
                  headline={data.strapiPage.Headline}
                />
              </main>
            </div>
        }
        {
          data.strapiPage.path === '/impressum' &&
            <div className="container is-widescreen content-container basic">
              <main>
                <Basic
                  content={data.strapiPage.content}
                />
              </main>
            </div>
        }
        {
          data.strapiPage.path === '/dataprotection' &&
            <div className="container is-widescreen content-container basic">
              <main>
                <Basic
                  content={data.strapiPage.content}
                />
              </main>
            </div>
        }
        {
          data.strapiPage.path === '/legal' &&
            <div className="container is-widescreen content-container basic">
              <main>
                <Basic
                  content={data.strapiPage.content}
                />
              </main>
            </div>
        }
          <Footer/>
        </div>
    </div>
  )
}

export default Layout
