import React from "react"
import ReactMarkdown from "react-markdown"

const AboutLayout = ({imageURL, headline, content, secondContent}) => (
  <div>
    <div className="columns is-multiline desktop">
      <div className="column">
        <div className="content container first" >
          <ReactMarkdown source={content} />
        </div>
      </div>
      <div className="column" >
        <div className="container image">
          <img alt="Gestalttherapeutin Luisa Karle" src={imageURL} />
        </div>
      </div>
    </div>
    <div className="mobile">
        <div className="container image">
          <img alt="" src={imageURL} />
        </div>
        <div className="content container first" >
            <ReactMarkdown source={content} />
        </div>
    </div>
    <div className="columns">
      <div className="column is-full">
        <div className="content is-widescreen container second" >
            <ReactMarkdown source={secondContent} />
        </div>
      </div>
    </div>
  </div>
)

export default AboutLayout