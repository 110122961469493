
import React, { useState, useEffect } from "react"
import { StaticQuery, Link, graphql } from "gatsby"
  // change state on scroll
  //TODO footer shows too soon
  
const Footer = () => {
  const footerHeight=40;
  const [height, setHeight] = useState(false);
  const calculateFooterHeight = () => {
      if ((window.innerHeight + window.scrollY - 100) >= document.body.offsetHeight) {
      setHeight(footerHeight);
    } else {
      setHeight(0)
    }
  }
  const mount = () => {
    calculateFooterHeight();
  
    const unmount = () => {
    }
    return unmount
    
  }
  const scroll = () => {
    document.addEventListener('scroll', calculateFooterHeight, { passive: false });
    return () => {
      document.removeEventListener('scroll', calculateFooterHeight);
    };
  }
  const resize = () => {
    document.addEventListener('resize', calculateFooterHeight, { passive: false });
    return () => {
      document.removeEventListener('resize', calculateFooterHeight);
    };
  }
  useEffect(mount, [])
  useEffect(scroll, [height]);
  useEffect(resize, [height]);
  return (
    <div style={{height: height+"px"}}  className="container is-widescreen footer">
      <div className="footer-list">
        <StaticQuery
        query ={graphql`  
            query {
                allStrapiPage(
                    sort: {
                        fields: [menuorder]
                        order: ASC
                    },
                    filter: {location: {eq: "footer"}}
                ) {
                    edges {
                        node {
                            strapiId
                            menuorder
                            Pagetitle
                            path
                        }
                    }

                }
            }
        `}
        render = {
          data => (
            data.allStrapiPage.edges.map(document => (
                <Link to={document.node.path} activeClassName="is-active" key={document.node.strapiId} >
                        {document.node.Pagetitle}
                </Link>
              )).reduce((prev, curr) => [
                prev, 
                <div key={prev.key+"-"+curr.key} className='divider' />, 
                curr
              ])
          )
        }
        />
      </div>
    </div>
  )

}

export default Footer