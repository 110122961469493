import React from 'react'
import "../style/mystyles.scss"
import { graphql } from 'gatsby'
import Layout from '../components/layout' 

const PageTemplate = ({ data }) => (
  <Layout data={data} />
)

export default PageTemplate

export const query = graphql`
  query StrapiPage($id: Int!) {
    strapiPage(strapiId: { eq: $id }) {
          id
          content
          secondColumnContent
          Pagetitle
          path
          Headline
          mainImage {
            publicURL
          }
    }
  }
`