
import React, { useState, useEffect } from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import { MdMenu } from 'react-icons/md';
const TopMenu = ({}) => {
const [displayMenu, setDisplayMenu] = useState("");
const handleClick = () => {
    if (displayMenu === "") {
        setDisplayMenu('bla')
    } else {
        setDisplayMenu('')
    }
    
};
return (<nav className="menu">
    <div className={`desktop ${displayMenu}`}>
        <StaticQuery
            query ={graphql`  
                query {
                    allStrapiPage(
                        sort: {
                            fields: [menuorder]
                            order: ASC
                        },
                        filter: {location: {eq: "menu"}}
                    ) {
                        edges {
                            node {
                                strapiId
                                menuorder
                                Pagetitle
                                path
                            }
                        }

                    }
                }
            `}
            render = {data => (data.allStrapiPage.edges.map(document => (
                <Link to={document.node.path} activeClassName="is-active" className="item" key={document.node.strapiId}>
                    {document.node.Pagetitle}
                </Link>
            )).reduce((prev, curr) => [prev, <div key={prev.key+"-"+curr.key}className='divider'></div>, curr]))}
            
        />
    </div>
    <div className={`mobile`}>
        <MdMenu onClick={handleClick} className=""/>
    </div>
</nav>)
}

export default TopMenu