import React from "react"
import ReactMarkdown from "react-markdown"
import ContactForm from '../../components/contactForm' 
import { MdPhone } from 'react-icons/md';
import { MdMailOutline } from 'react-icons/md';
import { MdHome } from 'react-icons/md';
import { StaticQuery, graphql } from "gatsby"
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
// const { executeRecaptcha } = useGoogleReCaptcha()
// const [token, setToken] = useState('')
const ContactLayout = ({content}) => {
 return (
  <div className="container content-container contact">
   <div className="columns contacts is-multiline is-centered">
    <div className="column is-narrow">
      <div className="content container" >
        <ReactMarkdown source={content} />
      </div>
      <div className="container contacts" >
        <StaticQuery
          query ={graphql`  
          query {
            allStrapiContact {
              edges {
                node {
                  id
                  contactType
                  contact
                }
              }
            }
            allStrapiAddress {
              edges {
                node {
                  id
                  street
                  city
                }
              }
            }
          }
          `}
          render = {data => (data.allStrapiContact.edges.map(document => (
              <div key={document.node.id} className={`columns contact is-mobile ${document.node.contactType}`}>
                <div className="column is-narrow icon">{document.node.contactType === "phone" ? <MdPhone /> : <MdMailOutline />}</div>
                <div className="column"><a href={document.node.contactType === "phone"? `tel:${document.node.contact}`: `mailto:${document.node.contact}`}>{document.node.contact}</a></div>
              </div>
          )).concat(
            <div key="addresses" className="columns addresses">{data.allStrapiAddress.edges.map(document => (
              <div key={document.node.id} className="column is-narrow">
                <div className="columns address is-mobile">
                  <div className="column is-narrow icon">{<MdHome />}</div>
                  <div className="column address">{document.node.street}<br/>{document.node.city}</div>
              </div>
            </div>
          ))} </div>
          ))}
        />
      </div>
    </div>
      <div className="column" >
        {/* <ContactForm />  */}
      </div>
    </div>
  </div>)
}
  

export default ContactLayout