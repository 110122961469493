import React from "react"
import ReactMarkdown from "react-markdown"

const OfferingsLayout = ({imageURL, content, secondContent, headline}) => (
  <div>
  <div className="columns is-centered is-vcentered is-multiline">
    <div className="column">
    <div className="container image">
        <img alt="Gestaltherapie Praxis Leipzig Luisa Karle" src={imageURL} />
      </div>
    </div>
    <div className="column" >
    <div className="content container first" >
        <ReactMarkdown source={content} />
        </div>
    </div>
  </div>
  <div className="columns">
    <div className="column is-full">
      <div className="content container is-widescreen second" >
          <ReactMarkdown source={secondContent} />
      </div>
    </div>
  </div>
</div>
  )

export default OfferingsLayout